import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
} from 'appRedux/constants';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  unreadCount: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        unreadCount: action.payload.filter(n => !n.read).length,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_NOTIFICATION_SUCCESS:
      const updatedData = state.data.map(n =>
        n.notificationId === action.payload ? { ...n, read: true } : n,
      );
      return {
        ...state,
        data: updatedData,
        unreadCount: state.unreadCount - 1,
      };
    default:
      return state;
  }
};
