import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, connect } from 'react-redux';
import { addMoneyAction } from 'appRedux/actions/wallets';
import Fbowithdrawal from '../fbo/withdrawal';
import { fetchUserData } from 'appRedux/actions/profile';
import FinancialWithdrawal from '../financialaccount/withdrawal';
import { requestProvidusAccount } from 'appRedux/actions/waitlist';
import Ngnwithdrawal from '../ngnwithdrawal/ngnwithdrawal';
import { Withaddbank, WithaddbankGh } from '../addbank/addbank';
import Ghswithdrawal from '../ngnwithdrawal/ghswithdrawal';
import Gbpwithdrawal from '../ngnwithdrawal/gbpwithdrawal';
import Zmbwithdrawal from '../ngnwithdrawal/zmbwithdrawal';
import Keswithdrawal from '../ngnwithdrawal/keswithdrawal';

const Sendmoney = props => {
  // console.log('sendmoney props: ', props);
  const [addbank, setAddBank] = useState('withdrawal');
  const sendMoney = useSelector(store => store.wallets.sendMoney);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  // const userWallets = useSelector(
  //   state => state.auth.authUser?.data?.wallets,
  //   _.isEqual,
  // );
  const dispatch = useDispatch();
  // console.log('Wallets:: ', props.allWallet);

  var closeModal = () => {
    props.fRefetch && props.fRefetch();
    dispatch(addMoneyAction({ addMoney: '' }));
  };

  var ngnBal = props.allWallet?.filter(item => item.currency === 'NGN');
  var usdBal = props.allWallet?.filter(item => item.currency === 'USD');
  var ghsBal = props.allWallet?.filter(item => item.currency === 'GHS');
  var gbpBal = props.allWallet?.filter(item => item.currency === 'GBP');
  var zmbBal = props.allWallet?.filter(item => item.currency === 'ZMW');
  var kesBal = props.allWallet?.filter(item => item.currency === 'KES');

  // console.log('ngnBal: ', ngnBal);

  switch (sendMoney) {
    case 'ngn':
      return addbank === 'withdrawal' ? (
        <Ngnwithdrawal
          balance={ngnBal ? ngnBal[0]?.balance / 100 : 0}
          user={userData}
          refetch={props.fetchUserData}
          openAddbank={() => setAddBank('addbank')}
          onClick={() => props.onClick()}
          closeModal={() => closeModal()}
          currency={'NGN'}
        />
      ) : (
        <Withaddbank
          closeModal={() => closeModal()}
          back={() => setAddBank('withdrawal')}
        />
      );
    case 'naira':
      return addbank === 'withdrawal' ? (
        <Ngnwithdrawal
          balance={ngnBal ? ngnBal[0]?.balance / 100 : 0}
          user={userData}
          refetch={props.fetchUserData}
          openAddbank={() => setAddBank('addbank')}
          onClick={() => props.onClick()}
          closeModal={() => closeModal()}
          currency={'NGN'}
        />
      ) : (
        <Withaddbank
          closeModal={() => closeModal()}
          back={() => setAddBank('withdrawal')}
        />
      );
    // case 'ghs':
    //   return (
    //     <Ghswithdrawal
    //       balance={ghsBal ? ghsBal[0].balance / 100 : 0}
    //       user={userData}
    //       refetch={props.fetchUserData}
    //       openAddbank={() => setAddBank('addbank')}
    //       onClick={() => props.onClick()}
    //       closeModal={() => closeModal()}
    //       currency={'GHS'}
    //     />
    //   )
    case 'ghs':
      return addbank === 'withdrawal' ? (
        <Ghswithdrawal
          balance={ghsBal ? ghsBal[0].balance / 100 : ''}
          user={userData}
          refetch={props.fetchUserData}
          openAddbank={() => setAddBank('addbank')}
          onClick={() => props.onClick()}
          closeModal={() => closeModal()}
          currency={'GHS'}
        />
      ) : (
        <WithaddbankGh
          closeModal={() => closeModal()}
          back={() => setAddBank('withdrawal')}
        />
      );
    case 'usd':
      return (
        <Fbowithdrawal
          rate={parseInt(props.rate?.MERCHANT_RATE / 100)}
          fullname={userData?.firstName + ' ' + userData.lastName}
          balance={usdBal ? usdBal[0]?.balance / 100 : ''}
          ngnbalance={ngnBal ? ngnBal[0]?.balance / 100 : ''}
          phone={userData.phoneNumber}
          refetch={props.fetchUserData}
          id={userData.id}
          onClick={() => props.onClick()}
          closeModal={closeModal}
          currency={'USD'}
        />
      );
    case 'pusd':
      return (
        <Fbowithdrawal
          rate={parseInt(props.rate?.MERCHANT_RATE / 100)}
          fullname={userData?.firstName + ' ' + userData.lastName}
          balance={usdBal}
          ngnbalance={ngnBal ? ngnBal[0]?.balance / 100 : ''}
          phone={userData.phoneNumber}
          refetch={props.fetchUserData}
          id={userData.id}
          onClick={() => props.onClick()}
          closeModal={closeModal}
          currency={'USD'}
        />
      );
    case 'busd':
      return (
        <FinancialWithdrawal
          rate={parseInt(props.rate?.EXCHANGE_RATE / 100)}
          balance={isNaN(props.foundersBalance) ? 0 : props.foundersBalance}
          phone={userData.phoneNumber}
          refetch={props.fetchUserData}
          id={userData.id}
          onClick={() => props.onClick()}
          closeModal={closeModal}
        />
      );
    case 'zmw':
      return (
        <Zmbwithdrawal
          balance={zmbBal ? zmbBal[0]?.balance / 100 : 0}
          user={userData}
          refetch={props.fetchUserData}
          openAddbank={() => setAddBank('addbank')}
          onClick={() => props.onClick()}
          closeModal={() => closeModal()}
          currency={'ZMW'}
        />
      );
    case 'kes':
      return (
        <Keswithdrawal
          balance={kesBal ? kesBal[0]?.balance / 100 : 0}
          user={userData}
          refetch={props.fetchUserData}
          openAddbank={() => setAddBank('addbank')}
          onClick={() => props.onClick()}
          closeModal={() => closeModal()}
          currency={'KES'}
        />
      );
    case 'gbp':
      return (
        <Gbpwithdrawal
          balance={gbpBal ? gbpBal[0]?.balance / 100 : 0}
          user={userData}
          refetch={props.fetchUserData}
          openAddbank={() => setAddBank('addbank')}
          onClick={() => props.onClick()}
          closeModal={() => closeModal()}
          currency={'GBP'}
        />
      );
    default:
      return <></>;
  }
};

const mapStateToProps = ({ waitlist, common }) => {
  const { message, loading } = waitlist;
  const { states } = common;
  return {
    message,
    loading,
    states,
  };
};

const mapDispatchToProps = {
  requestProvidusAccount,
  fetchUserData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Sendmoney);
