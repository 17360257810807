import React, { useState, useEffect } from 'react';
import GGcard from 'assets/physicalCard/GGcardP.png';
import CheckedIndicator from 'assets/physicalCard/Indicator.png';
import PendingIndicator from 'assets/physicalCard/pendingIndicator.png';
import { Platformbutton } from 'components/common/button/button';
import { Simplemodal } from 'components/common/simplifiedmodal';
import Temporarypin from './Temporarypin';
import './allcards.scss';
import api from 'appRedux/api';

const GGTpin = props => {
  const [GGTpinModal, setGGTpinModal] = useState(false);
  const [show, setShow] = useState(false);
  const [GGTpin, setGGTpin] = useState('');
  const [isCode, setIsCode] = useState(false);
  const [codeview, setcodeView] = useState(false);

  var handleRequestPin = cb => {
    api
      .post('/card/pc/1/send-code')
      .then(res => {
        setGGTpinModal(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  var handleConfirmPin = pin => {
    const payload = {
      code: pin,
    };
    api
      .post('/card/pc/1/confirm-code', payload)
      .then(res => {
        setIsCode(true);
        setGGTpinModal(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    setcodeView(isCode);
  }, [isCode]);

  return (
    <div>
      <Simplemodal onClick={() => setGGTpinModal(false)} open={GGTpinModal}>
        <Temporarypin
          setGGTpinModal={setGGTpinModal}
          setGGTpin={setGGTpin}
          GGTpin={GGTpin}
          handleConfirmPin={handleConfirmPin}
        />
      </Simplemodal>
      <div className="d-flex flex-col mt-15">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          <div className="GGSingleprogress">
            <img src={CheckedIndicator} alt="" />
            <p>Card Processing</p>
          </div>

          <div className="GGcardPendingLines"></div>

          <div className="GGSingleprogress">
            <img src={CheckedIndicator} alt="" />
            <p>Delivery</p>
          </div>

          <div className="GGcardPendingLines"></div>

          <div className="GGSingleprogress">
            <img src={PendingIndicator} alt="" />
            <p>Activation</p>
          </div>
        </div>

        <div
          className=" mx-auto "
          style={{
            marginTop: '30px',
            marginBottom: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '25rem',
            gap: '20px',
          }}
        >
          <img
            src={GGcard}
            style={{ height: '15rem', width: '25rem' }}
            alt=""
          />

          {/* Temporary pin false */}

          {!codeview ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h1 style={{ fontSize: '20px', color: '#67A948' }}>
                Card Status
              </h1>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  color: '#2B5219',
                }}
              >
                Your GlobalGeng card has been delivered to your address. To get
                your temporary PIN, click on the button below
              </p>

              <div style={{ marginTop: '10px', width: '100%' }}>
                <Platformbutton
                  type="normal"
                  name={'Get Temporary PIN'}
                  click={handleRequestPin}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h1 style={{ fontSize: '20px', color: '#67A948' }}>
                Temporary PIN{' '}
              </h1>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  color: '#2B5219',
                }}
              >
                To activate your GlobalGeng Card, change the PIN at recommended
                banks like{' '}
                <span style={{ color: '#67A948' }}>
                  Zenith, Globus, Jaiz, Providus or any other Microfinance bank.
                </span>{' '}
                Once changed. your card can be used at any ATM or POS terminal
              </p>
              <div className="viewTpin">
                {show ? (
                  <p>
                    {props.temporaryPin === null
                      ? `${GGTpin} PIN `
                      : props.temporaryPin}
                  </p>
                ) : (
                  <p>****</p>
                )}

                <i
                  className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
                  onClick={() => setShow(!show)}
                  style={{
                    fontSize: '12px',
                    color: '#67a948',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GGTpin;
