import React, { useEffect, useState } from 'react';
import { Success } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import './fundcard.scss';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Vestirate } from 'components/common/vestirate/vestirate';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  errorMessage,
  formatterUSD,
  getUsdDepositRate,
  removeCommaAmount,
  walletToCharge,
} from 'helpers/utils';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
// import { Comingsoon } from "components/common/comingsoon/comingsoon";
// import Loader from "components/Loader";
import { connect } from 'react-redux';
import { fundCard, liquidateCard } from 'appRedux/actions/cards';
import { Platformbutton } from 'components/common/button/button';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { useRates } from 'helpers/hooks';
import { Transreview } from 'components/common/transactionreview/review';
import { radio_ngn } from 'assets/assets';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Textbal } from 'components/bank/littlebalance/littlebalance';
import _ from 'lodash';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';
// import Loader from 'components/Loader';

const Fundcard = props => {
  // console.log('fundCard Props: ', props);
  const [data, setData] = useState({
    walletToCharge: '',
    amount: '',
    cardId: props.cardId,
    transactionPin: '',
  });
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(0);
  const { pricesData } = useRates();
  // eslint-disable-next-line
  const setWalletOption = value => {
    setData({ ...data, walletToCharge: value });
  };

  var setInput = e => {
    var name = e.target.name;
    var value = parseFloat(
      e.target.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/,/g, ''),
    ).toLocaleString('en-US');
    e.target.validity.valid && setData({ ...data, [name]: value });
  };

  var setPin = value => {
    setData({ ...data, transactionPin: value });
  };

  var goToStart = () => {
    setStep(0);
  };

  var remove = () => {
    setData({
      ...data,
      walletToCharge: '',
      amount: '',
      cardId: '',
      transactionPin: '',
    });
  };

  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf('.') + val + 1);
    return Number(str);
  }

  // eslint-disable-next-line
  var fundCallBack = value => {
    setStep(value);
    // props.cb && props.cb()
  };
  var fundCard = (myData, name) => {
    // var myData = {walletToCharge:data.walletToCharge, amount : data.amount.replace(/,/g, ''), transactionPin:data.transactionPin, cardId: data.cardId};
    props.fundCard(myData, name, fundCallBack);
  };

  var liqCard = () => {
    var myData = {
      amount: data.amount.replace(/,/g, '') * 100,
      cardPin: data.transactionPin,
    };
    props.liquidateCard(myData, data.cardId, fundCallBack);
  };

  var convertUSD = () => {
    api
      .post('/wallet/conversion-usd', {
        transactionOtp: data.transactionPin,
        amount: data.amount.replace(/,/g, '') * 100,
        currency: walletToCharge(data.walletToCharge),
        description: 'USD Conversion',
      })
      .then(res => {
        // console.log(res);
        openNotificationWithIcon(res.data.message, 'USD Conversion', 'success');
        setStep(3);
        setMessage(res.data.message);
        remove();
        props.cb && props.cb();
      })
      .catch(error => {
        openNotificationWithIconErr(
          errorMessage(error),
          'USD Conversion',
          'error',
        );
        setMessage(error.data.message);
        setStep(4);
        props.cb && props.cb();
      });
  };

  var _rendersteps = () => {
    switch (step) {
      // case 0:

      // return  <Comingsoon
      //     title="Under Maintenance"
      //     subtitle="Card Funding is currently under maintenance. We are sorry you can not create a new virtual card. Kindly check back later. You can pay for WES and SEVIS FEES, IHS-UK, TUITION FEE & Kindly enrol for US Account waitlisting and Others."
      //     button="Close"
      //     onClick= {() => props.closeModal()}
      //   />
      case 0:
        return (
          <Wallettype
            data={data}
            setWalletOption={setWalletOption}
            setStep={setStep}
            selectableWallets={props.wallet}
          />
        );
      case 1:
        return (
          <Amount
            data={data}
            balance={props.balance}
            selectableWallets={props.wallet}
            setInput={setInput}
            setStep={setStep}
            ParseFloat={ParseFloat}
            name={props.name}
            currency={props.currency}
            rate={
              props.currency === 'NGN'
                ? pricesData?.cardRate / 100
                : props.name === 'Checkbook'
                ? pricesData?.cardrate / 100
                : pricesData?.EXCHANGE_RATE / 100
            }
            convert={convertUSD}
          />
        );
      case 2:
        return (
          <Enterpin
            fundCard={fundCard}
            loading={props.cardLoading}
            setPin={setPin}
            setStep={setStep}
            data={data}
            name={props.name}
            ParseFloat={ParseFloat}
            currency={props.currency}
            rate={
              props.currency === 'NGN'
                ? pricesData?.cardRate / 100
                : props.name === 'Checkbook'
                ? pricesData?.cardRate / 100
                : pricesData?.EXCHANGE_RATE / 100
            }
            finish={props.action === 'liquidate' ? liqCard : convertUSD}
            myData={{
              cardId: data.cardId,
              currency: walletToCharge(data.walletToCharge),
              amount: removeCommaAmount(data.amount) * 100,
              transactionOtp: data.transactionPin,
              // description: 'Card Funding',
              // charges: +removeCommaAmount(
              //   formatterUSD.format(
              //     formatterUSD.format(
              //       getUsdDepositRate(removeCommaAmount(data.amount)),
              //     ) * 100,
              //   ),
              // ),
            }}
          />
        );
      case 3:
        return (
          <Success
            title={`Transaction Successful`}
            subtitle={props.message}
            // subtitle={
            //   props.currency === 'NGN'
            //     ? props.message
            //     : `You successfully funded your Vesti card.`
            // }
            btn="Done, Thank You."
            onClick={() => {
              props.closeModal();
              props.cb && props.cb();
            }}
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            button="Try Again"
            onClick={() => setStep(2)}
            // onClick={props.currency === 'NGN' ? () => setStep(1) : goToStart}
            title={`Transaction Failed`}
            subtitle={props.message}
            type="error"
          />
        );
      default:
        return <></>;
    }
  };

  return _rendersteps();
};

const Amount = props => {
  const [newRate, setNewRate] = useState('');
  const [fundCharges, setFundCharges] = useState(1);
  // console.log('walletToCharge props: ', props);
  var amount = removeCommaAmount(props.data.amount);
  const selectedWallet = props.selectableWallets?.find(
    wallet => wallet.currency === props.data.walletToCharge,
  );
  var finalRate = newRate?.cardRate / 100;

  var rate = formatterUSD.format(amount * finalRate);
  var balance = selectedWallet.balance / 100 > amount * finalRate;

  useEffect(() => {
    api
      .get(`/vesti-fx/lists?currency=${selectedWallet?.currency}`)
      .then(res => {
        // console.log(res);
        setNewRate(res.data);
      })
      .catch(err => err);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        setFundCharges(res.data?.data?.data[0]?.cardFundingCharges / 10);
        // console.log(res)
      })
      .catch(err => err);
  }, []);

  var __renderType = () => {
    switch (props.data.walletToCharge) {
      case 'NGN':
        return (
          props.data.amount &&
          (props.data.walletToCharge === 'NGN' || props.currency === 'NGN') &&
          props.data.amount !== 'NaN' && (
            <p className="fundcard__get">
              {'$' +
                props.data.amount +
                ` you get charged ≈ ₦${formatterUSD.format(
                  removeCommaAmount(amount) * finalRate,
                )} +
                
                
                ₦${
                  Math.round(
                    (fundCharges / 100) * props.data.amount * finalRate,
                  ) < finalRate
                    ? finalRate
                    : Math.round(
                        (fundCharges / 100) * props.data.amount * finalRate,
                      )
                } fee `}{' '}
            </p>
          )
        );
      case 'GHS':
        return (
          props.data.amount &&
          (props.data.walletToCharge === 'GHS' || props.currency === 'GHS') &&
          props.data.amount !== 'NaN' && (
            <p className="fundcard__get">
              {' '}
              {'$' +
                props.data.amount +
                ` you get charged ≈ ₵${formatterUSD.format(
                  removeCommaAmount(amount) * finalRate,
                )} + ₵${
                  Math.round(
                    (fundCharges / 100) * props.data.amount * finalRate,
                  ) < finalRate
                    ? finalRate
                    : Math.round(
                        (fundCharges / 100) * props.data.amount * finalRate,
                      )
                } fee `}{' '}
            </p>
          )
        );
      case 'KES':
        return (
          props.data.amount &&
          (props.data.walletToCharge === 'KES' || props.currency === 'KES') &&
          props.data.amount !== 'NaN' && (
            <p className="fundcard__get">
              {' '}
              {'$' +
                props.data.amount +
                ` you get charged ≈ ksh${formatterUSD.format(
                  removeCommaAmount(amount) * finalRate,
                )} +  ksh${formatterUSD.format(
                  getUsdDepositRate(removeCommaAmount(amount)) * finalRate,
                )} fee `}{' '}
            </p>
          )
        );
      case 'USD_CENTS':
        return (
          props.data.amount &&
          props.data.amount > 0 &&
          props.data.amount !== 'NaN' && (
            <p className="fundcard__get">
              {' '}
              {'$' +
                props.data.amount +
                ` you get ≈ $${formatterUSD.format(
                  amount,
                )} + $${formatterUSD.format(
                  getUsdDepositRate(amount),
                )} fee `}{' '}
            </p>
          )
        );
      default:
        return (
          props.data.amount &&
          (props.data.walletToCharge === 'NGN' || props.currency === 'NGN') &&
          props.data.amount !== 'NaN' && (
            <p className="fundcard__get">
              {' '}
              {'$' +
                props.data.amount +
                ` you get ≈ ₦${formatterUSD.format(
                  removeCommaAmount(amount) * finalRate,
                )} in your NGN wallet`}{' '}
            </p>
          )
        );
    }
  };

  var __renderBal = () => {
    switch (props.data.walletToCharge) {
      case 'NGN':
        return (
          <Textbal
            wallet={'NGN'}
            bal={removeCommaAmount(selectedWallet.balance / 100)}
            currency={'₦'}
          />
        );
      case 'USD_CENTS':
        return (
          <Textbal
            wallet={'USD'}
            bal={removeCommaAmount(selectedWallet.balance / 100)}
            currency={'$'}
          />
        );
      case 'GHS':
        return (
          <Textbal
            wallet={'GHS'}
            bal={removeCommaAmount(selectedWallet.balance / 100)}
            currency={'₵'}
          />
        );
      case 'ZSH':
        return (
          <Textbal
            wallet={'ZSH'}
            bal={removeCommaAmount(selectedWallet.balance / 100)}
            currency={'zsh'}
          />
        );
      case 'KES':
        return (
          <Textbal
            wallet={'KES'}
            bal={removeCommaAmount(selectedWallet.balance / 100)}
            currency={'ksh'}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="fundcard">
      <Titlesubtitle
        steps={`Step ${
          selectedWallet.currency === 'NGN' ? ' 1 of 2' : ' 1 of 3'
        }`}
        title={
          props.name === 'Apto'
            ? 'Fund your Black dollar card'
            : props.currency === 'NGN'
            ? 'Convert USD to NGN'
            : 'Fund your dollar virtual card'
        }
        subtitle={`Enter Amount (in USD) ${
          props.currency === 'NGN' ? ' You Want To convert' : 'to Fund With'
        }`}
      />

      {props.data.walletToCharge !== 'USD_CENTS' && (
        <Vestirate
          card="true"
          reverse={props.currency === props.data.walletToCharge ? false : true}
          rate={finalRate}
          currency={selectedWallet.currency}
        />
      )}
      <div className="fundcard__bcol">
        <div className="fundcard__scol">
          <Amountinputcurrency
            type="text"
            currency="$"
            name="amount"
            placeholder={`Enter Amount ${
              props.currency === 'NGN' ? ' You Want To convert' : 'to Fund With'
            }`}
            value={
              props.data.amount === 'NaN'
                ? 0
                : props.data.amount.toLocaleString('en-US')
            }
            disabled={false}
            onChange={props.setInput}
            pattern="[0-9,.]*"
          />
          {__renderBal()}
          <span className="fundcard__scol">
            {__renderType()}
            {props.data.walletToCharge === 'NGN' &&
              props.data.walletToCharge !== 'USD_CENTS' &&
              props.data.amount &&
              props.data.amount !== 'NaN' &&
              balance === false && (
                <AlertError
                  body={`You have less than ₦${rate} in your Vesti Naira wallet.`}
                />
              )}
            {props.data.walletToCharge === 'GHS' &&
              props.data.walletToCharge !== 'USD_CENTS' &&
              props.data.amount &&
              props.data.amount !== 'NaN' &&
              balance === false && (
                <AlertError
                  body={`You have less than ₵${rate} in your Vesti Cedis wallet.`}
                />
              )}
            {props.data.walletToCharge === 'USD_CENTS' &&
              props.data.amount &&
              props.data.amount !== 'NaN' &&
              balance === false && (
                <AlertError
                  body={`You have less than $${removeCommaAmount(
                    props.data.amount,
                  ).toLocaleString('en-us')} in your Vesti USD wallet.`}
                />
              )}
          </span>
        </div>
        {props.data.walletToCharge === 'NGN' ||
        props.data.walletToCharge === 'GHS' ? (
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={!props.data.amount || balance === false}
            // disabled={props.data.amount ? false : true}
            click={() => props.setStep(2)}
          />
        ) : (
          <Backcontinue
            text="Confirm and  Continue"
            goBack={() => props.setStep(0)}
          >
            <Platformbutton
              name="Continue"
              type="normal"
              disabled={props.data.amount && balance === true ? false : true}
              click={() => props.setStep(2)}
            />
          </Backcontinue>
        )}
      </div>
      {/* </div> */}
    </div>
  );
};

// eslint-disable-next-line
const Wallettype = props => {
  return (
    <div className="fundcard">
      <Titlesubtitle
        steps="Step 1 of 3"
        title="Select wallet to charge from"
        subtitle={`Select the wallet you want to fund from.`}
      />
      <div className="fundcard__bcol">
        {props.selectableWallets?.map((item, index) => (
          <div style={{ width: '100%' }} key={index}>
            {item.currency === 'NGN' && (
              <RadioOption
                image={radio_ngn}
                changed={value => props.setWalletOption(value)}
                id="1"
                isSelected={props.data.walletToCharge === 'NGN'}
                label="With Naira Wallet"
                sublabel="The amount will be deducted from your Vesti Naira Wallet."
                value="NGN"
              />
            )}
            {item.currency === 'GHS' && (
              <RadioOption
                image={radio_ngn}
                changed={value => props.setWalletOption(value)}
                id="2"
                isSelected={props.data.walletToCharge === 'GHS'}
                label="With Cedis Wallet"
                sublabel="The amount will be deducted from your Vesti Cedis Wallet."
                value="GHS"
              />
            )}
            {item.currency === 'KES' && (
              <RadioOption
                image={radio_ngn}
                changed={value => props.setWalletOption(value)}
                id="3"
                isSelected={props.data.walletToCharge === 'KES'}
                label="With Shillings Wallet"
                sublabel="The amount will be deducted from your Vesti Shillings Wallet."
                value="KES"
              />
            )}
          </div>
        ))}

        <Platformbutton
          name="Continue"
          type="normal"
          disabled={props.data.walletToCharge ? false : true}
          click={() => props.setStep(1)}
        />
      </div>
    </div>
  );
};

const Enterpin = props => {
  const [newRate, setNewRate] = useState('');
  const [cardfundCharges, setCarFundCharges] = useState(1);
  const [loading, setLoading] = useState(true);

  var handleFunding = () => {
    props.fundCard(props.myData, props.name);
  };
  var amount = formatterUSD.format(props.data.amount);
  var finalRate = newRate?.cardRate / 100;
  var rate = formatterUSD.format(amount * finalRate);

  useEffect(() => {
    api
      .get(`/vesti-fx/lists?currency=${props.data.walletToCharge}`)
      .then(res => {
        setNewRate(res.data);
        setLoading(false);
      })
      .catch(err => err);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        // console.log('cardFundingCharges: ', res);
        // console.log(res.data?.data?.data[0].cardFundingCharges / 10);
        setCarFundCharges(res.data?.data?.data[0].cardFundingCharges / 10);
        setLoading(false);
      })
      .catch(err => err);
  }, []);
  return (
    <div className="fundcard__final">
      {props.data.walletToCharge === 'GHS' && (
        <Transreview
          data={[
            { title: 'Transaction Type', value: 'Funding' },
            { title: 'Amount', value: `$${amount}` },
            { title: 'Amount GHS', value: `₵${rate}` },
            { title: 'Rate', value: `₵${finalRate}` },

            // {
            //   title: 'Charges',
            //   value: loading ? (
            //     <Loader />
            //   ) : Math.round((cardfundCharges / 100) * amount * finalRate) <
            //     finalRate ? (
            //     `₵ ${finalRate}`
            //   ) : (
            //     `₵ ${Math.round((cardfundCharges / 100) * amount * finalRate)}`
            //   ),
            // },
          ]}
        />
      )}

      {props.data.walletToCharge === 'NGN' && (
        <Transreview
          data={[
            { title: 'Transaction Type', value: 'Funding' },
            { title: 'Amount', value: `$${amount}` },
            { title: 'Amount NGN', value: `₦${rate}` },
            { title: 'Rate', value: `₦${finalRate}` },

            // {
            //   title: 'Charges',
            //   value: loading ? (
            //     <Loader />
            //   ) : Math.round((cardfundCharges / 100) * amount * finalRate) <
            //     finalRate ? (
            //     `₦ ${finalRate}`
            //   ) : (
            //     `₦ ${Math.round((cardfundCharges / 100) * amount * finalRate)}`
            //   ),
            // },
          ]}
        />
      )}

      {props.data.walletToCharge === 'USD_CENTS' && (
        <Transreview
          data={[
            { title: 'Transaction Type', value: 'Funding' },
            { title: 'Amount', value: `$${amount}` },
            {
              title: 'Charges',
              value: `$${formatterUSD.format(
                getUsdDepositRate(removeCommaAmount(amount)),
              )}`,
            },
          ]}
        />
      )}

      <Comptransaction
        fetcher={fetchTransactionOTP}
        setPin={props.setPin}
        loading={props.loading}
        len={4}
        lower={true}
      >
        <Backcontinue text="Continue" goBack={() => props.setStep(1)}>
          <Platformbutton
            name={props.name !== 'Apto' ? 'Initiate Transaction' : 'Finalize'}
            type="normal"
            click={props.currency === 'NGN' ? props.finish : handleFunding}
          />
        </Backcontinue>
      </Comptransaction>
    </div>
  );
};

const mapStateToProps = ({ cards }) => {
  const { message, cardLoading } = cards;
  return {
    message,
    cardLoading,
  };
};

const mapDispatchToProps = {
  fundCard,
  liquidateCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fundcard);
