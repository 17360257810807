import api from 'appRedux/api';
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
} from 'appRedux/constants';

// Action Creators
export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
});

export const fetchNotificationsSuccess = data => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchNotificationsFailure = error => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const updateNotificationSuccess = notificationId => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notificationId,
});

export const fetchNotifications = () => async dispatch => {
  dispatch(fetchNotificationsRequest());
  try {
    const response = await api.get(`/notification/fetch-all`);
    const data = response.data.data;

    dispatch(fetchNotificationsSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchNotificationsFailure(error));
  }
};

export const updateNotification = notificationId => async dispatch => {
  try {
    await api.patch(`/notification/${notificationId}`);
    dispatch(updateNotificationSuccess(notificationId));
  } catch (error) {
    console.error(error);
  }
};
