import React from 'react';
import _ from 'lodash';
import Depositwallet from 'components/deposit/deposit';
import { useDispatch, useSelector, connect } from 'react-redux';
import { addMoneyAction } from 'appRedux/actions/wallets';
import Fbodeposit from '../fbo/deposit';
import { fetchUserData } from 'appRedux/actions/profile';
import Deposit from '../financialaccount/deposit';
import { requestProvidusAccount } from 'appRedux/actions/waitlist';
import Depositmoney from '../addmoney/depositfund';
import Gbpdeposit from '../fbo/gbpdeposit';

const Addmoney = props => {
  // console.log("All wallet addmoney",props);
  const addMoney = useSelector(store => store.wallets.addMoney);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  // const userWallets = useSelector(
  //   state => state.auth.authUser?.data?.wallets,
  //   _.isEqual,
  // );

  const dispatch = useDispatch();

  var closeModal = () => {
    dispatch(addMoneyAction({ addMoney: '' }));
  };

  var ngnBal = props.allWallet?.filter(item => item.currency === 'NGN');
  var usdBal = props.allWallet?.filter(item => item.currency === 'USD');
  var ghsBal = props.allWallet?.filter(item => item.currency === 'GHS');
  var gbpBal = props.allWallet?.filter(item => item.currency === 'GBP');
  var zmwbal = props.allWallet?.filter(item => item.currency === 'ZMW');
  var kesBal = props.allWallet?.filter(item => item.currency === 'KES');

  //console.log("$; ", usdBal);
  //console.log("₦; ", ngnBal);
  //console.log('GH¢; ', ghsBal);
  // console.log('£ balance :: ', gbpBal);
  // console.log('zmw balance :: ', zmwbal);

  switch (addMoney) {
    case 'naira':
      return (
        <Depositwallet
          name="Naira"
          userData={userData}
          userWallets={props.allWallet}
          cb={() => closeModal()}
          request={() =>
            props.requestProvidusAccount({ email: userData?.email }, closeModal)
          }
        />
      );
    case 'ngn':
      return (
        <Depositwallet
          name="Naira"
          userData={userData}
          userWallets={props.allWallet}
          cb={() => closeModal()}
          request={() =>
            props.requestProvidusAccount({ email: userData?.email }, closeModal)
          }
        />
      );
    case 'usd':
      return (
        <Fbodeposit
          rate={parseInt(props.rate?.merchantRate / 100)}
          userWallets={props.allWallet}
          fullname={userData?.firstName + ' ' + userData.lastName}
          balance={usdBal ? usdBal[0]?.balance / 100 : 0}
          ngnbalance={ngnBal ? ngnBal[0]?.balance / 100 : 0}
          ghsbalance={ghsBal ? ghsBal[0]?.balance / 100 : 0}
          zmwbalance={zmwbal ? zmwbal[0]?.balance / 100 : 0}
          kesbalance={kesBal ? kesBal[0]?.balance / 100 : 0}
          phone={userData.phoneNumber}
          refetch={props.fetchUserData}
          id={userData.id}
          closeModal={closeModal}
        />
      );
    case 'pusd':
      return (
        <Fbodeposit
          rate={parseInt(props.rate?.merchantRate / 100)}
          userWallets={props.allWallet}
          fullname={userData?.firstName + ' ' + userData.lastName}
          balance={usdBal ? usdBal[0]?.balance / 100 : 0}
          ngnbalance={ngnBal ? ngnBal[0]?.balance / 100 : 0}
          ghsbalance={ghsBal ? ghsBal[0]?.balance / 100 : 0}
          zmwbalance={zmwbal ? zmwbal[0]?.balance / 100 : 0}
          kesbalance={kesBal ? kesBal[0]?.balance / 100 : 0}
          phone={userData.phoneNumber}
          refetch={props.fetchUserData}
          id={userData.id}
          closeModal={closeModal}
        />
      );
    case 'gbp':
      return (
        <Gbpdeposit
          name={addMoney}
          rate={parseInt(props.rate?.merchantRate / 100)}
          userWallets={props.allWallet}
          fullname={userData?.firstName + ' ' + userData.lastName}
          balance={gbpBal ? gbpBal[0]?.balance / 100 : 0}
          ngnbalance={ngnBal ? ngnBal[0]?.balance / 100 : 0}
          ghsbalance={ghsBal ? ghsBal[0]?.balance / 100 : 0}
          zmwbalance={zmwbal ? zmwbal[0]?.balance / 100 : 0}
          kesbalance={kesBal ? kesBal[0]?.balance / 100 : 0}
          phone={userData.phoneNumber}
          refetch={props.fetchUserData}
          id={userData.id}
          closeModal={closeModal}
        />
      );
    case 'busd':
      return (
        <Deposit
          rate={parseInt(props.rate?.EXCHANGE_RATE / 100)}
          userWallets={props.allWallet}
          balance={isNaN(props.foundersBalance) ? 0 : props.foundersBalance}
          ngnbalance={ngnBal[0].balance / 100}
          phone={userData.phoneNumber}
          sourceId={props.sourceId}
          refetch={props.fetchUserData}
          id={userData.id}
          closeModal={closeModal}
        />
      );
    case 'cedis':
    case 'ghc':
    case 'ghs':
    case 'kwacha':
    case 'zmw':
    case 'kes':
      return (
        <Depositmoney
          name={addMoney}
          userWallets={props.allWallet}
          userData={userData}
          ghsbalance={ghsBal ? ghsBal[0]?.balance / 100 : 0}
          zmwbalance={zmwbal ? zmwbal[0]?.balance / 100 : 0}
          kesbalance={kesBal ? kesBal[0]?.balance / 100 : 0}
          closeModal={() => closeModal()}
          request={() =>
            props.requestProvidusAccount({ email: userData?.email }, closeModal)
          }
        />
      );
    default:
      return <></>;
  }
};

const mapStateToProps = ({ waitlist, common }) => {
  const { message, loading } = waitlist;
  const { states } = common;
  return {
    message,
    loading,
    states,
  };
};

const mapDispatchToProps = {
  requestProvidusAccount,
  fetchUserData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Addmoney);
