import Loader from 'components/Loader';
import {
  __renderWalletName,
  __renderWalletSvg,
  formatAmount,
  getCurrencyCode,
} from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { claimModal } from 'appRedux/actions/Common';
import arrowhand from '../../../assets/arrowhand.svg';
import analytics from '../../../assets/analytics.svg';
import transgraph from '../../../assets/transgraph.svg';
import debitgraph from '../../../assets/debitssgraph.svg';
import creditsgraph from '../../../assets/creditsgraph.svg';
import savingsimage from '../../../assets/topsavings.svg';
import './balancecard.scss';
import { navigate } from '@reach/router';
import _ from 'lodash';
import { Smallempty } from 'components/common/empty/empty';
import { SelectCurrencyTwo } from '../selectcurrencyTwo';

import { useLocation } from '@reach/router';
import api from 'appRedux/api';
import { Modal } from 'antd';
import NewSavings from 'containers/Admin/Savings/NewSavings';
import { openUpdateBox } from 'appRedux/actions/update';

export const Wallets = props => {
  // console.log('Wallet Props: ', props);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const location = useLocation();
  const data = useSelector(state => state.wallets.wallets, _.isEqual);
  const userData = useSelector(
    state => (state.auth.userData ? state.auth.userData : []),
    _.isEqual,
  );
  // console.log(selected.currency);

  const selectedCurrency = selected?.currency;

  // Set the selectedCurrency value in local storage
  localStorage.setItem('currency', selectedCurrency);

  // eslint-disable-next-line
  const [fulldata, setData] = useState(data);

  const currencies = data;

  // eslint-disable-next-line
  const handleCurrencyChange = selectedCurrency => {
    // setSelected(selectedCurrency);
  };

  const alternateDefaultCurrency = getCurrencyCode(
    userData?.country ? userData?.country : userData?.address?.country,
  );
  const defaultCurrency = data?.find(item => item.default === true);

  const finalCurrency = defaultCurrency
    ? defaultCurrency
    : data?.find(item => item.currency === alternateDefaultCurrency);

  // console.log(finalCurrency);

  useEffect(() => {
    if (selected.name) return;
    if (data?.length > 0) {
      setSelected(finalCurrency);
    }
  }, [data, selected?.name]);

  var walletType = props?.type?.toLowerCase();

  // console.log('walletType:: ', walletType);

  if (data?.length > 0) {
    var id = fulldata?.findIndex(function(item) {
      return item.value === walletType;
    });

    function walletType2() {
      return location.search.split('=')[1] || 'bank';
    }

    // console.log('Location: ', walletType2());

    // switch (props?.type?.toLowerCase()){
    // console.log('Type2 ', walletType2());
    switch (walletType2()) {
      case 'naira':
      case 'ngn':
      case 'cedis':
      case 'ghc':
      case 'ghs':
      case 'gbp':
      case 'kwacha':
      case 'zmw':
      case 'kes':
      case 'busd':
      case 'pusd':
      case 'usd':
        return (
          <div className="wallet-container">
            <div className="wallets">
              <div className="wallets__row">
                <SingleSavingsCard
                  name="Safelock wallet"
                  currency="₦"
                  userdata={props.userdata}
                />
                <Singlebalance2
                  data={
                    data.filter(item =>
                      item.name
                        .toLocaleUpperCase()
                        .includes(walletType2().toLocaleUpperCase()),
                    )[0]
                  }
                  show={show}
                  transfer={props.transfer}
                  home={walletType === 'bank' ? true : false}
                  setShow={setShow}
                  userdata={props.userdata}
                  request={props.request}
                  nairaAccount={props.nairaAccount}
                  id={id}
                  addMoney={props.addMoney}
                  sendMoney={props.sendMoney}
                  walletType={walletType}
                  walletType2={walletType2}
                  currencies={currencies}
                  setSelected={setSelected}
                  selected={selected}
                  handleCurrencyChange={handleCurrencyChange}
                  accountNumber={props.accountNumber}
                  // filteredData={filteredData}
                />
              </div>
            </div>
          </div>
        );
      case 'bank':
        return (
          <>
            <div className="wallet-container">
              <div className="wallets">
                <div className="wallets__row">
                  <SingleSavingsCard
                    name="Safelock wallet"
                    currency="₦"
                    userdata={props.userdata}
                  />

                  <Singlebalance
                    data={data}
                    show={show}
                    transfer={props.transfer}
                    home={walletType === 'bank' ? true : false}
                    setShow={setShow}
                    userdata={props.userdata}
                    request={props.request}
                    nairaAccount={props.nairaAccount}
                    currencies={currencies}
                    accountNumber={props.accountNumber}
                    handleCurrencyChange={handleCurrencyChange}
                    // filteredData={filteredData}
                    setSelected={setSelected}
                    selected={selected}
                    stripeVirtualAccountNumber={
                      props.stripeVirtualAccountNumber
                    }
                    id={id}
                  />

                  <AnalyticsCard name="Analytics" />
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <Smallempty subtitle="Wallet  does not exist" />;
    }
  } else {
    return <Loader />;
  }
};

const Singlebalance = props => {
  // console.log(props)
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  // const [curr, setCurr] = useState('');
  // const [eyeColor, setEyeColor] = useState();

  //   console.log('Single balance: ', props);

  useEffect(() => {
    props.setSelected(props.selected);
  }, []);

  var __renderLink = () => {
    switch (props.selected.name) {
      // case 'Business USD Balance':
      case 'USD wallet':
        return <Seedetails click={props.seeDetails} />;
      case 'Naira Balance':
      case 'Naira wallet':
      case 'NGN Balance':
      case 'NGN wallet':
        return props.accountNumber ? (
          <p className="wallet__details">
            {/* Account details: {props.data?.bank?.number} */}
            Account details: {props.accountNumber}
            {/* <i className="far fa-question-circle" /> */}
          </p>
        ) : // <Seedetails click={() => props.nairaAccount()} />
        props.userdata?.hasPendingTransfer?.status === 'PENDING' ? (
          <ClaimMoney
            click={() => dispatch(claimModal(true))}
            // click={() => alert('I dey for you')}
          />
        ) : (
          <p className="wallet__details" onClick={() => props.request()}>
            Request account number <i className="fas fa-arrow-right" />{' '}
          </p>
        );
      default:
        return <p className="wallet__details"></p>;
    }
  };

  //console.log(props.currencies);
  //console.log('Selected Wallet:: ', props.selected);
  // console.log('Selected Wallet Name:: ', props.selected.name);

  return (
    <div className={`wallet  ${__renderWalletName(props.selected?.name)}`}>
      {props.selected?.name && (
        <img
          className="wallet-image"
          src={__renderWalletSvg(props.selected?.name)}
          alt="wallet"
        />
      )}
      <div className="wallet__top">
        <div className="wallet__topinner">
          <p className="title">{props.selected?.name}</p>
          <SelectCurrencyTwo
            setSelected={props.setSelected}
            selected={props.selected}
            options={props.currencies.map(item => ({
              value: item.currency,
              label: item.name.toLocaleUpperCase(),
              // image: __renderWalletSvg(props.selected?.name),
              image: item.currencyFlag,
              ...item,
            }))}
            handleCurrencyChange={props.handleCurrencyChange}
          />
        </div>
        <span className="wallet__balance">
          <p className="wallet__curr">
            {props?.selected?.currency?.toLocaleUpperCase()}
          </p>
          <span>
            <p style={{ fontSize: '32px' }}>
              {show
                ? props.selected?.balance.toString() === 'NaN'
                  ? 0.0
                  : (props.selected?.balance / 100).toLocaleString('en-US')
                : 'XXX'}
            </p>
            <i
              className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
              onClick={() => setShow(!show)}
              style={{
                marginBottom: '20px',
                fontSize: '16px',
                color: 'white',
                cursor: 'pointer',
              }}
            />
          </span>
        </span>
      </div>
      {!props.transfer ? (
        props.home === true ? (
          <GotoMain
            value={props?.selected?.name?.toLocaleLowerCase().split(' ')[0]}
          />
        ) : (
          <div className="wallet__bottom">
            {__renderLink()}
            <div className="wallet__btns">
              {/* props.selected.buttons */}
              {['Add Money', 'Send Money'].map((item, index) => (
                <button
                  key={index}
                  className={`wallet__btn ${index === 1 && ' --outline'}`}
                  onClick={() =>
                    index === 0
                      ? props.addMoney(props.walletType)
                      : props.sendMoney(props.walletType)
                  }
                >
                  {' '}
                  {item}{' '}
                </button>
              ))}
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

const Singlebalance2 = props => {
  // console.log('Single balance: ', props);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    props.setSelected(props.selected);
  }, []);

  var __renderLink = () => {
    switch (props.selected.name) {
      case 'Business USD Balance':
      case 'USD wallet':
        return <Seedetails click={props.seeDetails} />;
      case 'Naira Balance':
      case 'Naira wallet':
      case 'NGN wallet':
      case 'NGN Wallet':
        return props.accountNumber ? (
          <p className="wallet__details">
            Account details: {props.accountNumber}
          </p>
        ) : props.userdata?.hasPendingTransfer?.status === 'PENDING' ? (
          <ClaimMoney click={() => dispatch(claimModal(true))} />
        ) : (
          <p className="wallet__details" onClick={() => props.request()}>
            Request account number <i className="fas fa-arrow-right" />{' '}
          </p>
        );
      default:
        return <p className="wallet__details"></p>;
    }
  };

  //   console.log(props.currencies);
  // console.log('Selected walletType:: ', props.walletType);
  // console.log('Selected Wallet:: ', props.selected);
  // console.log('Selected Wallet Name:: ', props.selected.name);

  return (
    <div className={`wallet  ${__renderWalletName(props.selected?.name)}`}>
      {props.selected?.name && (
        <img
          className="wallet-image"
          src={__renderWalletSvg(props.selected?.name)}
          alt="wallet"
        />
      )}
      <div className="wallet__top">
        <div className="wallet__topinner">
          <p className="title">{props.selected?.name}</p>
        </div>
        <span className="wallet__balance">
          <p className="wallet__curr">
            {props?.selected?.currency?.toLocaleUpperCase()}
          </p>
          <span>
            <p>
              {show
                ? props.selected?.balance.toString() === 'NaN'
                  ? 0.0
                  : (props.selected?.balance / 100).toLocaleString('en-US')
                : 'XXX'}
            </p>
            <i
              className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
              onClick={() => setShow(!show)}
            />
          </span>
        </span>
      </div>

      {!props.transfer ? (
        props.home === true ? (
          <GotoMain
            value={props?.selected?.name?.toLocaleLowerCase().split(' ')[0]}
          />
        ) : (
          <div className="wallet__bottom">
            {__renderLink()}
            <div className="wallet__btns">
              {/* props.selected.buttons */}
              {['Add Money', 'Send Money'].map((item, index) => (
                <button
                  key={index}
                  className={`wallet__btn ${index === 1 && ' --outline'}`}
                  onClick={() =>
                    index === 0
                      ? props.addMoney(props.walletType)
                      : props.sendMoney(props.walletType)
                  }
                >
                  {' '}
                  {item}{' '}
                </button>
              ))}
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export const Seedetails = props => {
  return (
    <p className="wallet__details" onClick={() => props.click()}>
      Click to see account details
      <i className="far fa-question-circle" />
    </p>
  );
};

export const GotoMain = props => {
  // console.log('More details: ', props);
  return (
    <div
      className="wallet__details --more"
      onClick={() => navigate(`/bank?wallet=${props.value}`)}
    >
      <p>More details</p>
      <i className="fas fa-arrow-up"></i>
    </div>
  );
};

export const ClaimMoney = props => {
  return (
    <div className="wallet__claimbutton" onClick={() => props.click()}>
      <p className="wallet__details">Claim pending money</p>
      <img className="arrowhand" src={arrowhand} alt="icon" />
    </div>
  );
};

const SingleSavingsCard = props => {
  const dispatch = useDispatch();
  // console.log('SingleSavingsCard:', props);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [savingsPlans, setSavingsPlans] = useState([]);
  const [savingsTotal, setSavingsTotal] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTotalSavings();
  }, []);

  useEffect(() => {
    getSavings();
  }, []);

  const getSavings = async () => {
    const url = '/safe-lock/list';
    setLoading(true);
    try {
      const res = await api.get(url);
      // console.log(res.data);
      const { data } = res.data;
      setSavingsPlans(data);
      setLoading(false);
    } catch (error) {
      //console.log({ ...error });
      setLoading(false);
    }
  };

  const getTotalSavings = async () => {
    const url = '/safe-lock/total-savings-amount?currency=NGN';
    setLoading(true);
    try {
      const res = await api.get(url);
      // console.log(res.data);
      const { data } = res.data;
      setSavingsTotal(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //console.log({ ...error });
    }
  };

  var openUpdateModal = () => {
    dispatch(openUpdateBox());
  };

  return (
    <div className={`wallet  ${__renderWalletName(props.name)}`}>
      <img className="wallet-image" src={savingsimage} alt="savings" />

      <div className="wallet__top">
        <div className="wallet__topinner">
          <p className="title">TOTAL SAVINGS</p>
        </div>
        <span className="wallet__balance">
          <p className="wallet__curr">
            {props?.selected?.currency?.toLocaleUpperCase()}
          </p>
          {savingsPlans?.length > 0 ? (
            <span>
              <p className="text-lg lg:text-xl">
                {props?.currency?.toLocaleUpperCase()}{' '}
                {show ? savingsTotal.totalLockedAmount / 100 : 'XXX'}
              </p>
              <i
                className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
                onClick={() => setShow(!show)}
                style={{
                  marginBottom: '20px',
                  fontSize: '16px',
                  color: 'white',
                  cursor: 'pointer',
                }}
              />
            </span>
          ) : (
            <p className="wallet__curr mt-2">
              You have not created a savings plan
            </p>
          )}
        </span>
      </div>

      <div className="wallet__bottom">
        {loading ? (
          '...'
        ) : savingsPlans?.length > 0 ? (
          <>
            <p className="wallet__savingsinterest">
              Click on the button below to see your savings
            </p>
            <div className="wallet__btns">
              <button
                className={`wallet__btn`}
                onClick={() => {
                  navigate('/savings');
                }}
              >
                See your savings plans
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="wallet__savingsinterest">
              Click on the button below to start saving
            </p>
            <div className="wallet__btns">
              <button
                className={`wallet__btn`}
                onClick={() => {
                  if (
                    props.userdata?.verifiedKyc === true ||
                    props.userdata?.verifiedKyc === 'APPROVED'
                  ) {
                    setOpen(true);
                  } else {
                    openUpdateModal();
                  }
                }}
              >
                Create Savings Plan
              </button>
            </div>
          </>
        )}
      </div>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        open={open}
        onCancel={() => setOpen(false)}
        destroyOnClose
        footer=""
        className="new-modal"
        centered={true}
        okButtonProps={{ style: { display: 'none' } }}
        styles={{
          mask: {
            background: 'rgba(103, 169, 72, 0.2)',
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        <NewSavings close={() => setOpen(false)} user={props.userdata} />
      </Modal>
    </div>
  );
};

const AnalyticsCard = props => {
  // console.log('SingleSavingsCard:', props);

  return (
    <div className={`wallet analyticsCard  ${__renderWalletName(props.name)}`}>
      {/* <img className="wallet-image" src={savingsimage} alt="savings" /> */}

      <div className="wallet__top">
        <div className="wallet__topinner">
          <p className="title">WALLET ANALYTICS</p>
          <p className="text-white rounded-3 bg-secondary p-1">Coming soon</p>
        </div>
      </div>
      <div className="wallet__graphs -mt-2">
        <img src={transgraph} alt="" width={100} />
        <img src={debitgraph} alt="" width={100} />
        <img src={creditsgraph} alt="" width={100} />
      </div>
    </div>
  );
};
