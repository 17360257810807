import React from 'react';
import GGcard from 'assets/physicalCard/GGcardP.png';
import CheckedIndicator from 'assets/physicalCard/Indicator.png';
import PendingIndicator from 'assets/physicalCard/pendingIndicator.png';

import './allcards.scss';

const GGpending = () => {
  return (
    <div style={{ marginTop: '40px' }}>
      <div className="d-flex flex-col mt-15">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          <div className="GGSingleprogress">
            <img src={CheckedIndicator} alt="" />
            <p>Card Processing</p>
          </div>

          <div className="GGcardPendingLines"></div>

          <div className="GGSingleprogress">
            <img src={PendingIndicator} alt="" />
            <p>Delivery</p>
          </div>

          <div className="GGcardPendingLines"></div>

          <div className="GGSingleprogress">
            <img src={PendingIndicator} alt="" />
            <p>Activation</p>
          </div>
        </div>

        <div
          className=" mx-auto "
          style={{
            marginTop: '30px',
            marginBottom: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '25rem',
            gap: '20px',
          }}
        >
          <img
            src={GGcard}
            alt=""
            style={{ height: '15rem', width: '25rem' }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1 style={{ fontSize: '20px', color: '#67A948' }}>Card Status</h1>
            <p
              style={{
                textAlign: 'center',
                fontSize: '13px',
                color: '#2B5219',
              }}
            >
              Your GlobalGeng Card is{' '}
              <span style={{ color: '#E99F0C' }}>being processed.</span> You
              will be informed when it is ready for delivery or pickup.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GGpending;
