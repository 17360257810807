import React, { useState } from 'react';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Success } from 'components/common/success/success';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import vin from 'assets/vdeposit.svg';
import vout from 'assets/vout.svg';
import vrev from 'assets/vrev.svg';
// import limit from "assets/cardlimit.svg"
// import freeze from "assets/confirmfreeze.svg"
import del from 'assets/deletecard.svg';
import errorsvg from 'assets/error-2.svg';
import { Shortinfo } from 'components/common/shortinfo/shortinfo';
import { Newprompt } from 'components/common/prompt/prompt';
import moment from 'moment';
import { Platformbutton } from 'components/common/button/button';
import './cards.scss';
import { formatterUSD, transStatus } from 'helpers/utils';

export const Singletransaction = props => {
  return (
    <div className="singletransaction" key={props.key}>
      <p className="singletransaction__title">{props.data.date}</p>
      <div className="singletransaction__trans">
        {(props.data.transactions ?? []).map((item, index) => (
          <Atransaction data={item} key={index} rate={props.rate} />
        ))}
      </div>
    </div>
  );
};

const Atransaction = props => {
  var currency =
    props.data.currency && props.data.currency.split('_')[0] === 'NGN'
      ? 'N'
      : '$';
  const type =
    props.data.type === 'CARD_LIQUIDATION'
      ? 'card liquidation'
      : props.data.type.split('_')[0];
  const sign =
    props.data.type === 'CARD_LIQUIDATION'
      ? '-'
      : props.data.type.split('_')[0] === 'DEBIT'
      ? '-'
      : '+';
  const transdate = moment(
    props.data.created_at || props.data.createdAt,
    'YYYY-MM-DD HH:mm:ss',
  ).format('YYYY-MM-DD HH:mm:ss');
  const charges = props.data.charges ? props.data.charges : 0;
  return (
    <div className="singletransaction__single">
      <span className="singletransaction__left">
        <img
          src={
            type.includes('CREDIT') || type.includes('DEPOSIT')
              ? vin
              : type.includes('reversal')
              ? vrev
              : vout
          }
          alt="transaction svg"
        />
        <div className="singletransaction__desc">
          {
            <p>
              A {type} of{' '}
              <strong>
                {currency}
                {formatterUSD.format(props.data.amount / 100)}{' '}
              </strong>{' '}
              • <strong> {transdate} </strong> •{' '}
              <strong>
                {props.data.description && props.data.description}
              </strong>
            </p>
          }
          <div className={`status`}>
            <p
              className={`statusIcon --${transStatus(props.data.status)} `}
            ></p>{' '}
            {transStatus(props.data.status)}
          </div>
        </div>
      </span>
      <div className="singletransaction__right">
        <p
          className={`singletransaction__amount ${
            sign === '+' ? ' --credit' : ' --debit'
          } `}
        >
          {sign}
          {currency}
          {formatterUSD.format(props.data.amount / 100)}
        </p>
        {props.data.charges ? (
          <p className="singletransaction__charges">-${charges / 100}</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const ChangePin = props => {
  const [step, setStep] = useState(0);

  var changeIt = () => {
    props.changecardPin(
      {
        password: props.data.password,
        cardId: props.data.cardId,
        newCardPin: props.data.newCardPin,
      },
      props.name,
      setStep,
    );
  };

  var goToStart = () => {
    setStep(0);
  };
  const __renderPIN = () => {
    switch (step) {
      case 0:
        return (
          <form
            className="cardaction__col"
            onSubmit={() => setStep(1)}
            style={{ width: '100%' }}
          >
            <Inputfloat
              type="password"
              label="Vesti password"
              name="password"
              placeholder="Enter Your Vesti password"
              value={props.data.password}
              disabled={false}
              onChange={props.setField}
            />
            <Platformbutton
              name="Continue"
              type="normal"
              disabled={props.data.password ? false : true}
              click={() => setStep(1)}
            />
          </form>
        );
      case 1:
        return (
          <div className="cardaction__col">
            <Comptransaction
              title="Enter your desired new five(4) digits card PIN"
              setPin={props.setPinfield}
              loading={props.loading}
              goBack={() => setStep(0)}
              lower={true}
              len={props.name === 'Checkbook' ? 4 : 5}
            >
              <Backcontinue text="Continue" goBack={() => setStep(0)}>
                <Platformbutton
                  name={props.btn !== '' ? props.btn : ` Change PIN`}
                  disabled={
                    props.data.newCardPin
                      ? props.btn === '' || !props.loading
                        ? false
                        : true
                      : true
                  }
                  click={() => changeIt()}
                />
              </Backcontinue>
            </Comptransaction>
          </div>
        );
      case 2:
        return (
          <Success
            title={`Succesful`}
            subtitle={`You successfully changed your virtual card PIN.`}
            btn="Done, Thank You."
            onClick={props.closepinModal}
          />
        );
      case 3:
        return (
          <Success
            image={errorsvg}
            button="Try Again"
            onClick={goToStart}
            title={`PIN update failed`}
            subtitle={props.message}
            // subtitle={'Your virtual card PIN change failed, try again.'}
            type="error"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {step <= 1 && (
        <Titlesubtitle
          steps={`Step ${step + 1} of 1`}
          title="Change Virtual card PIN"
          subtitle={
            step === 0
              ? `Enter your Vesti password to start this process.`
              : 'Enter your desired new five(4) digits card PIN.'
          }
        />
      )}
      <div className="mb-4"></div>
      {__renderPIN()}
    </>
  );
};

// export const Cardactions = (props) => {

//     var status =  props.data.cardStatus === undefined ? 'Active' : props.data.cardStatus
//     var data = [
//         {
//             image:order,
//             name:'Order physical card',
//             click :props.order
//         },
//         {
//             image:change,
//             name:'Change card PIN',
//             click :props.pin
//         }
//         ,
//         {
//             image:frozen,
//             name:status.includes('Inactive') ? 'Unfreeze Card' : 'freeze Card',
//             click :props.freeze
//         }
//     ]

//     return (
//         <div className={`cardactions ${props.action ? ' --active' :''}`}>
//             {
//                 (props.name.includes('Apto') ? data:props.name.includes('Stripe') ? data.slice(1,3) : data.slice(1,4) ).map((item, index)=> (
//                     <span key={index} onClick ={()=> item.click()}>
//                         <img src={item.image} alt="Action"/>
//                         <p>{item.name}</p>
//                     </span>
//                 ))
//             }
//         </div>
//     )
// }

export const Freezecard = props => {
  return (
    <Newprompt img={del}>
      <section className="freezecard">
        <span>
          <p>Delete This Card</p>
          <h6>
            {' '}
            Are you sure you want to delete this card ? note the{' '}
            <strong>${props.balance}</strong> will be liquidated to your Naira
            wallet.
            <strong> @{props.rate} </strong>
          </h6>
        </span>
        <Backcontinue
          back="No! Cancel"
          goBack={() => props.cancel()}
          text="Yes, Delete Card"
          continue={() => props.freeze()}
        />
      </section>
    </Newprompt>
  );
};

export const Ordercard = props => {
  const [step, setStep] = useState(0);
  switch (step) {
    case 0:
      return (
        <Shipping
          data={props.data}
          setData={props.setData}
          ordercard={props.ordercard}
          setStep={setStep}
          setInput={props.setInput}
          states={props.states}
        />
      );
    case 2:
      return (
        <Success
          title={`Succesful`}
          subtitle={`You successfully placed an  order for your physical card.`}
          btn="Done, Thank You."
          onClick={props.closeModal}
        />
      );
    case 3:
      return (
        <Success
          image={errorsvg}
          button="Try Again"
          onClick={() => setStep(0)}
          title={`Failed`}
          subtitle={props.message}
          type="error"
        />
      );
    default:
      return <></>;
  }
};

const Shipping = props => {
  return (
    <div className="ordercard" style={{ width: '100%' }}>
      <Titlesubtitle
        title="Order Physical Card"
        subtitle="Easily create virtual cards to cater for your needs."
      />

      <Singleselect
        value={props.data.region}
        options={props.states}
        onChange={value => props.setData({ ...props.data, region: value })}
        placeholder="Select States"
      />

      <div className="ordercard__location">
        <Inputfloat
          type="text"
          label="locality"
          name="locality"
          placeholder="Enter Your Locality"
          value={props.data.locality}
          disabled={false}
          onChange={props.setInput}
        />

        <Inputfloat
          type="number"
          label="Postal Code"
          name="postal_code"
          placeholder="Postal Code"
          value={props.data.postal_Code}
          disabled={false}
          onChange={props.setInput}
        />
      </div>

      <Inputfloat
        type="text"
        label="Street Address"
        name="street_one"
        placeholder="Enter Your Street Address"
        value={props.data.street_one}
        disabled={false}
        onChange={props.setInput}
      >
        <p className="apto-error">
          {props.data.street_one.split(' ').length > 3
            ? 'This is more than the format provided'
            : ' '}
        </p>
        <Shortinfo subject="E.g 504 Ammonite Ct " />
      </Inputfloat>

      {/* disabled={Object.values(props.data).slice(0,10).every(value => !!value) && props.data.billingAddress.split(' ').length <= 3 ?  false : true     }  */}
      <button
        className="backcontinue"
        disabled={
          Object.values(props.data)
            .slice(0, 6)
            .every(value => !!value) &&
          props.data.street_one.split(' ').length <= 3
            ? false
            : true
        }
        onClick={() => props.ordercard(props.setStep)}
      >
        Order Card
      </button>
    </div>
  );
};
