import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import api from 'appRedux/api';
import { Tooltip } from 'antd';
import '../Cash/carddetails/carddetails.scss';
import './allcards.scss';
import { Link } from '@reach/router';
import { openUpdateBox } from 'appRedux/actions/update';
import Loader from 'components/Loader';
import { Simplemodal } from 'components/common/simplifiedmodal';
import larrow from 'assets/larrow.svg';
import rarrow from 'assets/rarrow.svg';
import { Empty } from 'components/common/empty/empty';
import vwhite from '../../../assets/vestiwhite.svg';
import Visa from '../../../assets/VisaIcon.svg';
import Mastercard from '../../../assets/mastercard.svg';
import VCardsTransactions from './cardsTransactions.js';
import { getCardImage } from 'helpers/utils';
import Slider from 'react-slick';
import Ordercard from 'components/vesticards/selectcard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import { useUserData } from 'helpers/hooks';

const VirtualcardPage = props => {
  // console.log('Virtual card page', props);
  // console.log('user KYC', props.userData?.verifiedKyc);
  const { refetch } = useUserData();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [mycardD, setMyCardD] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [active, setActive] = useState(false);

  const PrevButton = ({ onClick }) => (
    <button
      className="clickPrev"
      onClick={onClick}
      style={{ position: 'absolute', top: '40%', zIndex: '5', left: '-80px' }}
    >
      <img src={larrow} alt="" />
    </button>
  );

  const NextButton = ({ onClick }) => (
    <button
      className="clickRight"
      onClick={onClick}
      style={{ position: 'absolute', top: '40%', zIndex: '5', right: '-80px' }}
    >
      <img src={rarrow} alt="" />
    </button>
  );

  const settings = {
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,

    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 1300,
    //     settings: {
    //       slidesToShow: 2,
    //     },
    //   },

    //   {
    //     breakpoint: 3000,
    //     settings: {
    //       className: 'center',
    //       centerMode: true,
    //       slidesToShow: 2.15,
    //       slidesToScroll: 1,
    //     },
    //   },

    // ],
  };

  const lesscardsettings = {
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  useEffect(() => {
    async function fetchVirtualCardData() {
      // try {
      //   await setIsloading(true);

      //   const res = await api.get('/card/vc/all');
      //   console.log('All Cards response: ', res);
      //   const myRes = res.data?.data?.data || [];
      //   const newArray = myRes.map(obj => obj);

      //   setMyCardD(newArray);

      //   newArray.map(c => {
      //     setCurrentId(c.cardId);
      //   });

      //   setIsloading(false);
      // } catch (error) {
      //   console.error('Error fetching virtual card data:', error);
      //   setIsloading(false);
      // }

      try {
        await setIsloading(true);

        const res = await api.get('/card/vc/all');

        const myRes = res.data?.data?.data || []; // Use empty array if undefined

        // Filter cards where providerName is not 'Stpe'
        const filteredArray = myRes.filter(
          card => card.providerName !== 'Stripe',
        );

        setMyCardD(filteredArray);

        // Update the currentId based on the first card in the filtered array
        if (filteredArray.length > 0) {
          setCurrentId(filteredArray[0].cardId);
        }

        setIsloading(false);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching virtual card data:', error);
        setIsloading(false);
      }
    }

    fetchVirtualCardData();
  }, []);
  // console.log('currentId: ', currentId);

  const dispatch = useDispatch();

  var spinCardModal = () => {
    props.userData?.verifiedKyc === 'APPROVED' ||
    props.userData?.verifiedKyc === true
      ? // ? dispatch(openVirtual('mono'))
        setShowModal(true)
      : dispatch(openUpdateBox());
  };
  // eslint-disable-next-line
  const modifyState = value => setState({ ...state, ...value });

  const [state, setState] = useState({
    // modal: false,
    // withdrawalModal: false,
    // addbank: 'withdrawal',
    virtualCardModal: true,
  });

  // const { virtualCardModal } = state;

  return (
    <section>
      <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
        <Ordercard
          authData={props.userData}
          closeModal={() => setShowModal(false)}
          cb={refetch}
          refetch={refetch}
        />
      </Simplemodal>

      {isloading ? (
        <Loader />
      ) : mycardD?.length < 1 ? (
        <div className="mycard__empty">
          <Empty
            title="No Card"
            subtitle="You are yet to create a Card, click the button below to start process."
            click={() => spinCardModal()}
            // click={()=>{setShowModal(true)}}
            name="Create A Card"
            type="normal"
          />
        </div>
      ) : (
        <>
          {/* Virtual cards display section */}

          <section
            className="vcardSection flex  w-full "
            style={{ marginBottom: '5rem' }}
          >
            <Tooltip placement="right" title="Create Card">
              <div
                className="createVirtualCard"
                style={{ zIndex: '20' }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM20 15.9375H15.9375V20C15.9375 20.5125 15.5125 20.9375 15 20.9375C14.4875 20.9375 14.0625 20.5125 14.0625 20V15.9375H10C9.4875 15.9375 9.0625 15.5125 9.0625 15C9.0625 14.4875 9.4875 14.0625 10 14.0625H14.0625V10C14.0625 9.4875 14.4875 9.0625 15 9.0625C15.5125 9.0625 15.9375 9.4875 15.9375 10V14.0625H20C20.5125 14.0625 20.9375 14.4875 20.9375 15C20.9375 15.5125 20.5125 15.9375 20 15.9375Z"
                    fill="#518C36"
                  />
                </svg>
              </div>
            </Tooltip>

            {mycardD.length < 3 ? (
              <div className="vcardSection " style={{ width: '400px' }}>
                <Slider {...lesscardsettings}>
                  {mycardD.map(card => (
                    <div className="cardD" key={card.cardId}>
                      <Link
                        to="/cardsdetails"
                        className={`ccard whiteText ${
                          card.cardColor === 'Yellow' ? 'bluebg' : 'bg-black'
                        } `}
                        onClick={() => {
                          setActive(true);
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={
                              card.cardBrand === 'MasterCard'
                                ? Mastercard
                                : Visa
                            }
                            alt="visa"
                            style={{ width: '40px' }}
                          />
                          <img
                            src={vwhite}
                            alt="vesti white"
                            style={{ width: '40px' }}
                          />
                        </div>

                        <>
                          {card.cardNumber ? (
                            <p style={{ fontWeight: '400' }}>
                              {show
                                ? card.cardNumber.replace(/(\d{4})/g, '$1 ')
                                : card.cardNumber
                                ? 'XXXX XXXX XXXX' + card.cardNumber.slice(-4)
                                : 'XXXXXX'}
                              <i
                                className={`fas fa-${
                                  show ? 'eye-slash' : 'eye'
                                }`}
                                onClick={() => {
                                  active ? setShow(!show) : setShow(show);
                                }}
                                style={{ marginLeft: '4px' }}
                              ></i>
                            </p>
                          ) : (
                            <p style={{ fontWeight: '400' }}></p>
                          )}
                        </>

                        <div className="cardBtm">
                          <span className="">
                            <p
                              style={{
                                fontWeight: '300',
                                fontSize: '13px',
                                color: '#8A8A8B',
                              }}
                            >
                              BALANCE
                            </p>
                            <p style={{ fontSize: '24px', fontWeight: '700' }}>
                              $
                              {/* {(card.providerName === 'Checkbook' ||
                              card.balance === null
                                ? 0
                                : card.balance / 100
                              ).formatamount(card.balance / 100)} */}
                              {card.providerName === 'Checkbook' ||
                              card.balance === null ? (
                                <p>***</p>
                              ) : (
                                parseFloat(card.balance / 100)
                              )}
                              {/* {props.from
                                ? (card.balance / 100).toLocaleString(
                                    'en-us',
                                  )
                                : (
                                    parseInt(card.balance) / 100
                                  ).toLocaleString('en-us')} */}
                            </p>
                          </span>
                          <p style={{ fontSize: '12px', fontWeight: '700' }}>
                            {card.cardName.toUpperCase()}
                          </p>
                        </div>

                        <img
                          src={getCardImage(card.cardColor)}
                          className="gg"
                          style={{
                            position: 'absolute',
                            width: '20rem',
                            height: '17rem',
                            right: '50px',
                            bottom: '10px',
                          }}
                          alt="gg"
                        />
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="vcardSection " style={{ width: '400px' }}>
                {' '}
                {/* <div className="card-slider-container "></div> */}
                <Slider {...settings}>
                  {mycardD.map(card => (
                    <div className="cardD" key={card.cardId}>
                      <Link
                        to="/cardsdetails"
                        className={`ccard whiteText ${
                          card.cardColor === 'Yellow' ? 'bluebg' : 'bg-black'
                        } `}
                        onClick={() => {
                          setActive(true);
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={
                              card.cardBrand === 'MasterCard'
                                ? Mastercard
                                : Visa
                            }
                            alt="visa"
                            style={{ width: '40px' }}
                          />
                          <img
                            src={vwhite}
                            alt="vesti white"
                            style={{ width: '40px' }}
                          />
                        </div>

                        <>
                          {card.cardNumber ? (
                            <p style={{ fontWeight: '400' }}>
                              {show
                                ? card.cardNumber.replace(/(\d{4})/g, '$1 ')
                                : card.cardNumber
                                ? 'XXXX XXXX XXXX' + card.cardNumber.slice(-4)
                                : 'XXXXXX'}
                              <i
                                className={`fas fa-${
                                  show ? 'eye-slash' : 'eye'
                                }`}
                                onClick={() => {
                                  active ? setShow(!show) : setShow(show);
                                }}
                                style={{ marginLeft: '4px' }}
                              ></i>
                            </p>
                          ) : (
                            <p style={{ fontWeight: '400' }}></p>
                          )}
                        </>

                        <div className="cardBtm">
                          <span className="">
                            <p
                              style={{
                                fontWeight: '300',
                                fontSize: '13px',
                                color: '#8A8A8B',
                              }}
                            >
                              BALANCE
                            </p>
                            {/* {console.log(props)} */}
                            <p style={{ fontSize: '24px', fontWeight: '700' }}>
                              ${' '}
                              {card.providerName === 'Checkbook' ||
                              card.balance === null ? (
                                <p>***</p>
                              ) : (
                                parseFloat(card.balance / 100)
                              )}
                            </p>
                          </span>
                          <p style={{ fontSize: '12px', fontWeight: '700' }}>
                            {card.cardName.toUpperCase()}
                          </p>
                        </div>

                        <img
                          src={getCardImage(card.cardColor)}
                          className="gg"
                          style={{
                            position: 'absolute',
                            width: '20rem',
                            height: '17rem',
                            right: '50px',
                            bottom: '10px',
                          }}
                          alt="gg"
                        />
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </section>

          {/* Card Transaction Details Table section*/}
          <section>
            <VCardsTransactions id={currentId} />
          </section>
        </>
      )}
    </section>
  );
};

const mapStateToProps = ({ auth, domore }) => {
  const { userData } = auth;

  const { openVirtual } = domore;
  return {
    userData,
    openVirtual,
  };
};
const mapDispatchToProps = {
  openUpdateBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualcardPage);
