import {
  cash,
  merchant,
  airtime,
  profile,
  cards,
  member,
  feed,
  advice,
  pathway,
  cc,
  naira,
  kwacha,
  cedis,
  pesos,
  pounds,
  shillings,
} from 'assets/assets';
import { radio_cardusd, radio_ngnusd } from 'assets/assets';
import regimg from 'assets/regimg/wallet.png';
import regimgc from 'assets/regimg/cardsAuth.png';
import regimgf from 'assets/regimg/regimg-fees.png';
import regimgp from 'assets/regimg/pathwayAuth.png';

export const lgaList = {
  Abia: [
    'Aba North',
    'Aba South',
    'Arochukwu',
    'Bende',
    'Ikwuano',
    'Isiala Ngwa North',
    'Isiala Ngwa South',
    'Isuikwuato',
    'Obi Ngwa',
    'Ohafia',
    'Osisioma',
    'Ugwunagbo',
    'Ukwa East',
    'Ukwa West',
    'Umuahia North',
    'muahia South',
    'Umu Nneochi',
  ],
  Adamawa: [
    'Demsa',
    'Fufure',
    'Ganye',
    'Gayuk',
    'Gombi',
    'Grie',
    'Hong',
    'Jada',
    'Larmurde',
    'Madagali',
    'Maiha',
    'Mayo Belwa',
    'Michika',
    'Mubi North',
    'Mubi South',
    'Numan',
    'Shelleng',
    'Song',
    'Toungo',
    'Yola North',
    'Yola South',
  ],
  AkwaIbom: [
    'Abak',
    'Eastern Obolo',
    'Eket',
    'Esit Eket',
    'Essien Udim',
    'Etim Ekpo',
    'Etinan',
    'Ibeno',
    'Ibesikpo Asutan',
    'Ibiono-Ibom',
    'Ika',
    'Ikono',
    'Ikot Abasi',
    'Ikot Ekpene',
    'Ini',
    'Itu',
    'Mbo',
    'Mkpat-Enin',
    'Nsit-Atai',
    'Nsit-Ibom',
    'Nsit-Ubium',
    'Obot Akara',
    'Okobo',
    'Onna',
    'Oron',
    'Oruk Anam',
    'Udung-Uko',
    'Ukanafun',
    'Uruan',
    'Urue-Offong Oruko',
    'Uyo',
  ],
  Anambra: [
    'Aguata',
    'Anambra East',
    'Anambra West',
    'Anaocha',
    'Awka North',
    'Awka South',
    'Ayamelum',
    'Dunukofia',
    'Ekwusigo',
    'Idemili North',
    'Idemili South',
    'Ihiala',
    'Njikoka',
    'Nnewi North',
    'Nnewi South',
    'Ogbaru',
    'Onitsha North',
    'Onitsha South',
    'Orumba North',
    'Orumba South',
    'Oyi',
  ],
  // eslint-disable-next-line
  Anambra: [
    'Aguata',
    'Anambra East',
    'Anambra West',
    'Anaocha',
    'Awka North',
    'Awka South',
    'Ayamelum',
    'Dunukofia',
    'Ekwusigo',
    'Idemili North',
    'Idemili South',
    'Ihiala',
    'Njikoka',
    'Nnewi North',
    'Nnewi South',
    'Ogbaru',
    'Onitsha North',
    'Onitsha South',
    'Orumba North',
    'Orumba South',
    'Oyi',
  ],
  Bauchi: [
    'Alkaleri',
    'Bauchi',
    'Bogoro',
    'Damban',
    'Darazo',
    'Dass',
    'Gamawa',
    'Ganjuwa',
    'Giade',
    'Itas-Gadau',
    'Jama are',
    'Katagum',
    'Kirfi',
    'Misau',
    'Ningi',
    'Shira',
    'Tafawa Balewa',
    ' Toro',
    ' Warji',
    ' Zaki',
  ],

  Bayelsa: [
    'Brass',
    'Ekeremor',
    'Kolokuma Opokuma',
    'Nembe',
    'Ogbia',
    'Sagbama',
    'Southern Ijaw',
    'Yenagoa',
  ],
  Benue: [
    'Agatu',
    'Apa',
    'Ado',
    'Buruku',
    'Gboko',
    'Guma',
    'Gwer East',
    'Gwer West',
    'Katsina-Ala',
    'Konshisha',
    'Kwande',
    'Logo',
    'Makurdi',
    'Obi',
    'Ogbadibo',
    'Ohimini',
    'Oju',
    'Okpokwu',
    'Oturkpo',
    'Tarka',
    'Ukum',
    'Ushongo',
    'Vandeikya',
  ],
  Borno: [
    'Abadam',
    'Askira-Uba',
    'Bama',
    'Bayo',
    'Biu',
    'Chibok',
    'Damboa',
    'Dikwa',
    'Gubio',
    'Guzamala',
    'Gwoza',
    'Hawul',
    'Jere',
    'Kaga',
    'Kala-Balge',
    'Konduga',
    'Kukawa',
    'Kwaya Kusar',
    'Mafa',
    'Magumeri',
    'Maiduguri',
    'Marte',
    'Mobbar',
    'Monguno',
    'Ngala',
    'Nganzai',
    'Shani',
  ],
  'Cross River': [
    'Abi',
    'Akamkpa',
    'Akpabuyo',
    'Bakassi',
    'Bekwarra',
    'Biase',
    'Boki',
    'Calabar Municipal',
    'Calabar South',
    'Etung',
    'Ikom',
    'Obanliku',
    'Obubra',
    'Obudu',
    'Odukpani',
    'Ogoja',
    'Yakuur',
    'Yala',
  ],

  Delta: [
    'Aniocha North',
    'Aniocha South',
    'Bomadi',
    'Burutu',
    'Ethiope East',
    'Ethiope West',
    'Ika North East',
    'Ika South',
    'Isoko North',
    'Isoko South',
    'Ndokwa East',
    'Ndokwa West',
    'Okpe',
    'Oshimili North',
    'Oshimili South',
    'Patani',
    'Sapele',
    'Udu',
    'Ughelli North',
    'Ughelli South',
    'Ukwuani',
    'Uvwie',
    'Warri North',
    'Warri South',
    'Warri South West',
  ],

  Ebonyi: [
    'Abakaliki',
    'Afikpo North',
    'Afikpo South',
    'Ebonyi',
    'Ezza North',
    'Ezza South',
    'Ikwo',
    'Ishielu',
    'Ivo',
    'Izzi',
    'Ohaozara',
    'Ohaukwu',
    'Onicha',
  ],
  Edo: [
    'Akoko-Edo',
    'Egor',
    'Esan Central',
    'Esan North-East',
    'Esan South-East',
    'Esan West',
    'Etsako Central',
    'Etsako East',
    'Etsako West',
    'Igueben',
    'Ikpoba Okha',
    'Orhionmwon',
    'Oredo',
    'Ovia North-East',
    'Ovia South-West',
    'Owan East',
    'Owan West',
    'Uhunmwonde',
  ],

  Ekiti: [
    'Ado Ekiti',
    'Efon',
    'Ekiti East',
    'Ekiti South-West',
    'Ekiti West',
    'Emure',
    'Gbonyin',
    'Ido Osi',
    'Ijero',
    'Ikere',
    'Ikole',
    'Ilejemeje',
    'Irepodun-Ifelodun',
    'Ise-Orun',
    'Moba',
    'Oye',
  ],
  Rivers: [
    'Port Harcourt',
    'Obio-Akpor',
    'Okrika',
    'Ogu–Bolo',
    'Eleme',
    'Tai',
    'Gokana',
    'Khana',
    'Oyigbo',
    'Opobo–Nkoro',
    'Andoni',
    'Bonny',
    'Degema',
    'Asari-Toru',
    'Akuku-Toru',
    'Abua–Odual',
    'Ahoada West',
    'Ahoada East',
    'Ogba–Egbema–Ndoni',
    'Emohua',
    'Ikwerre',
    'Etche',
    'Omuma',
  ],
  Enugu: [
    'Aninri',
    'Awgu',
    'Enugu East',
    'Enugu North',
    'Enugu South',
    'Ezeagu',
    'Igbo Etiti',
    'Igbo Eze North',
    'Igbo Eze South',
    'Isi Uzo',
    'Nkanu East',
    'Nkanu West',
    'Nsukka',
    'Oji River',
    'Udenu',
    'Udi',
    'Uzo Uwani',
  ],
  'Federal Capital Territory': [
    'Abaji',
    'Bwari',
    'Gwagwalada',
    'Kuje',
    'Kwali',
    'Municipal Area Council',
  ],
  FCT: [
    'Abaji',
    'Bwari',
    'Gwagwalada',
    'Kuje',
    'Kwali',
    'Municipal Area Council',
  ],
  Gombe: [
    'Akko',
    'Balanga',
    'Billiri',
    'Dukku',
    'Funakaye',
    'Gombe',
    'Kaltungo',
    'Kwami',
    'Nafada',
    'Shongom',
    'Yamaltu-Deba',
  ],
  Imo: [
    'Aboh Mbaise',
    'Ahiazu Mbaise',
    'Ehime Mbano',
    'Ezinihitte',
    'Ideato North',
    'Ideato South',
    'Ihitte-Uboma',
    'Ikeduru',
    'Isiala Mbano',
    'Isu',
    'Mbaitoli',
    'Ngor Okpala',
    'Njaba',
    'Nkwerre',
    'Nwangele',
    'Obowo',
    'Oguta',
    'Ohaji-Egbema',
    'Okigwe',
    'Orlu',
    'Orsu',
    'Oru East',
    'Oru West',
    'Owerri Municipal',
    'Owerri North',
    'Owerri West',
    'Unuimo',
  ],
  Jigawa: [
    'Auyo',
    'Babura',
    'Biriniwa',
    'Birnin Kudu',
    'Buji',
    'Dutse',
    'Gagarawa',
    'Garki',
    'Gumel',
    'Guri',
    'Gwaram',
    'Gwiwa',
    'Hadejia',
    'Jahun',
    'Kafin Hausa',
    'Kazaure',
    'Kiri Kasama',
    'Kiyawa',
    'Kaugama',
    'Maigatari',
    'Malam Madori',
    'Miga',
    'Ringim',
    'Roni',
    'Sule Tankarkar',
    'Taura',
    'Yankwashi',
  ],
  Kaduna: [
    'Birnin Gwari',
    'Chikun',
    'Giwa',
    'Igabi',
    'Ikara',
    'Jaba',
    'Jema a',
    'Kachia',
    'Kaduna North',
    'Kaduna South',
    'Kagarko',
    'Kajuru',
    'Kaura',
    'Kauru',
    'Kubau',
    'Kudan',
    'Lere',
    'Makarfi',
    'Sabon Gari',
    'Sanga',
    'Soba',
    'Zangon Kataf',
    'Zaria',
  ],
  Kano: [
    'Ajingi',
    'Albasu',
    'Bagwai',
    'Bebeji',
    'Bichi',
    'Bunkure',
    'Dala',
    'Dambatta',
    'Dawakin Kudu',
    'Dawakin Tofa',
    'Doguwa',
    'Fagge',
    'Gabasawa',
    'Garko',
    'Garun Mallam',
    'Gaya',
    'Gezawa',
    'Gwale',
    'Gwarzo',
    'Kabo',
    'Kano Municipal',
    'Karaye',
    'Kibiya',
    'Kiru',
    'Kumbotso',
    'Kunchi',
    'Kura',
    'Madobi',
    'Makoda',
    'Minjibir',
    'Nasarawa',
    'Rano',
    'Rimin Gado',
    'Rogo',
    'Shanono',
    'Sumaila',
    'Takai',
    'Tarauni',
    'Tofa',
    'Tsanyawa',
    'Tudun Wada',
    'Ungogo',
    'Warawa',
    'Wudil',
  ],
  Katsina: [
    'Bakori',
    'Batagarawa',
    'Batsari',
    'Baure',
    'Bindawa',
    'Charanchi',
    'Dandume',
    'Danja',
    'Dan Musa',
    'Daura',
    'Dutsi',
    'Dutsin Ma',
    'Faskari',
    'Funtua',
    'Ingawa',
    'Jibia',
    'Kafur',
    'Kaita',
    'Kankara',
    'Kankia',
    'Katsina',
    'Kurfi',
    'Kusada',
    'Mai Adua',
    'Malumfashi',
    'Mani',
    'Mashi',
    'Matazu',
    'Musawa',
    'Rimi',
    'Sabuwa',
    'Safana',
    'Sandamu',
    'Zango',
  ],
  Kebbi: [
    'Aleiro',
    'Arewa Dandi',
    'Argungu',
    'Augie',
    'Bagudo',
    'Birnin Kebbi',
    'Bunza',
    'Dandi',
    'Fakai',
    'Gwandu',
    'Jega',
    'Kalgo',
    'Koko Besse',
    'Maiyama',
    'Ngaski',
    'Sakaba',
    'Shanga',
    'Suru',
    'Wasagu Danko',
    'Yauri',
    'Zuru',
  ],
  Kogi: [
    'Adavi',
    'Ajaokuta',
    'Ankpa',
    'Bassa',
    'Dekina',
    'Ibaji',
    'Idah',
    'Igalamela Odolu',
    'Ijumu',
    'Kabba Bunu',
    'Kogi',
    'Lokoja',
    'Mopa Muro',
    'Ofu',
    'Ogori Magongo',
    'Okehi',
    'Okene',
    'Olamaboro',
    'Omala',
    'Yagba East',
    'Yagba West',
  ],
  Kwara: [
    'Asa',
    'Baruten',
    'Edu',
    'Ekiti',
    'Ifelodun',
    'Ilorin East',
    'Ilorin South',
    'Ilorin West',
    'Irepodun',
    'Isin',
    'Kaiama',
    'Moro',
    'Offa',
    'Oke Ero',
    'Oyun',
    'Pategi',
  ],
  Lagos: [
    'Agege',
    'Ajeromi-Ifelodun',
    'Alimosho',
    'Amuwo-Odofin',
    'Apapa',
    'Badagry',
    'Epe',
    'Eti Osa',
    'Ibeju-Lekki',
    'Ifako-Ijaiye',
    'Ikeja',
    'Ikorodu',
    'Kosofe',
    'Lagos Island',
    'Lagos Mainland',
    'Mushin',
    'Ojo',
    'Oshodi-Isolo',
    'Shomolu',
    'Surulere',
  ],
  Nasarawa: [
    'Akwanga',
    'Awe',
    'Doma',
    'Karu',
    'Keana',
    'Keffi',
    'Kokona',
    'Lafia',
    'Nasarawa',
    'Nasarawa Egon',
    'Obi',
    'Toto',
    'Wamba',
  ],
  Niger: [
    'Agaie',
    'Agwara',
    'Bida',
    'Borgu',
    'Bosso',
    'Chanchaga',
    'Edati',
    'Gbako',
    'Gurara',
    'Katcha',
    'Kontagora',
    'Lapai',
    'Lavun',
    'Magama',
    'Mariga',
    'Mashegu',
    'Mokwa',
    'Moya',
    'Paikoro',
    'Rafi',
    'Rijau',
    'Shiroro',
    'Suleja',
    'Tafa',
    'Wushishi',
  ],
  Ogun: [
    'Abeokuta North',
    'Abeokuta South',
    'Ado-Odo Ota',
    'Egbado North',
    'Egbado South',
    'Ewekoro',
    'Ifo',
    'Ijebu East',
    'Ijebu North',
    'Ijebu North East',
    'Ijebu Ode',
    'Ikenne',
    'Imeko Afon',
    'Ipokia',
    'Obafemi Owode',
    'Odeda',
    'Odogbolu',
    'Ogun Waterside',
    'Remo North',
    'Shagamu',
  ],
  Ondo: [
    'Akoko North-East',
    'Akoko North-West',
    'Akoko South-West',
    'Akoko South-East',
    'Akure North',
    'Akure South',
    'Ese Odo',
    'Idanre',
    'Ifedore',
    'Ilaje',
    'Ile Oluji-Okeigbo',
    'Irele',
    'Odigbo',
    'Okitipupa',
    'Ondo East',
    'Ondo West',
    'Ose',
    'Owo',
  ],
  Osun: [
    'Atakunmosa East',
    'Atakunmosa West',
    'Aiyedaade',
    'Aiyedire',
    'Boluwaduro',
    'Boripe',
    'Ede North',
    'Ede South',
    'Ife Central',
    'Ife East',
    'Ife North',
    'Ife South',
    'Egbedore',
    'Ejigbo',
    'Ifedayo',
    'Ifelodun',
    'Ila',
    'Ilesa East',
    'Ilesa West',
    'Irepodun',
    'Irewole',
    'Isokan',
    'Iwo',
    'Obokun',
    'Odo Otin',
    'Ola Oluwa',
    'Olorunda',
    'Oriade',
    'Orolu',
    'Osogbo',
  ],
  Oyo: [
    'Afijio',
    'Akinyele',
    'Atiba',
    'Atisbo',
    'Egbeda',
    'Ibadan North',
    'Ibadan North-East',
    'Ibadan North-West',
    'Ibadan South-East',
    'Ibadan South-West',
    'Ibarapa Central',
    'Ibarapa East',
    'Ibarapa North',
    'Ido',
    'Irepo',
    'Iseyin',
    'Itesiwaju',
    'Iwajowa',
    'Kajola',
    'Lagelu',
    'Ogbomosho North',
    'Ogbomosho South',
    'Ogo Oluwa',
    'Olorunsogo',
    'Oluyole',
    'Ona Ara',
    'Orelope',
    'Ori Ire',
    'Oyo',
    'Oyo East',
    'Saki East',
    'Saki West',
    'Surulere',
  ],
  Plateau: [
    'Bokkos',
    'Barkin Ladi',
    'Bassa',
    'Jos East',
    'Jos North',
    'Jos South',
    'Kanam',
    'Kanke',
    'Langtang South',
    'Langtang North',
    'Mangu',
    'Mikang',
    'Pankshin',
    'Qua an Pan',
    'Riyom',
    'Shendam',
    'Wase',
  ],
  Sokoto: [
    'Binji',
    'Bodinga',
    'Dange Shuni',
    'Gada',
    'Goronyo',
    'Gudu',
    'Gwadabawa',
    'Illela',
    'Isa',
    'Kebbe',
    'Kware',
    'Rabah',
    'Sabon Birni',
    'Shagari',
    'Silame',
    'Sokoto North',
    'Sokoto South',
    'Tambuwal',
    'Tangaza',
    'Tureta',
    'Wamako',
    'Wurno',
    'Yabo',
  ],
  Taraba: [
    'Ardo Kola',
    'Bali',
    'Donga',
    'Gashaka',
    'Gassol',
    'Ibi',
    'Jalingo',
    'Karim Lamido',
    'Kumi',
    'Lau',
    'Sardauna',
    'Takum',
    'Ussa',
    'Wukari',
    'Yorro',
    'Zing',
  ],
  Yobe: [
    'Bade',
    'Bursari',
    'Damaturu',
    'Fika',
    'Fune',
    'Geidam',
    'Gujba',
    'Gulani',
    'Jakusko',
    'Karasuwa',
    'Machina',
    'Nangere',
    'Nguru',
    'Potiskum',
    'Tarmuwa',
    'Yunusari',
    'Yusufari',
  ],
  Zamfara: [
    'Anka',
    'Bakura',
    'Birnin Magaji Kiyaw',
    'Bukkuyum',
    'Bungudu',
    'Gummi',
    'Gusau',
    'Kaura Namoda',
    'Maradun',
    'Maru',
    'Shinkafi',
    'Talata Mafara',
    'Chafe',
    'Zurmi',
  ],
};

export const accountType = [
  {
    value: 'checking',
    label: 'checking',
  },
  {
    value: 'savings',
    label: 'savings',
  },
];

export const holderType = [
  {
    value: 'individual',
    label: 'individual',
  },
  {
    value: 'business',
    label: 'business',
  },
];

export const usdAccountCodes = [
  {
    value: 'ROUTING_NUMBER',
    title: 'ACH transfer',
    subtitle: 'ACH transfer to a domestic USA Financial Account.',
  },
  {
    value: 'SAME_DAY_ROUTING_NUMBER',
    title: 'Same Day wire',
    subtitle: 'Same day wire to a domestic USA Financial Account.',
  },
  // {
  //   value: 'BIC',
  //   title: 'An International account (BIC Code)',
  //   subtitle: 'No, it’s not an account in the US',
  // },
  // {
  //   value: 'SWIFT',
  //   title: 'An International account (SWIFT Code)',
  //   subtitle: 'No, it’s not an account in the US',
  // },
];

export const usdAccountDeposit = [
  // {
  //     value:'ACCOUNT',
  //     title:'Add money into an account',
  //     subtitle:'Add money by making a bank transfer into an account.'
  // },
  {
    image: radio_cardusd,
    value: 'CARD',
    title: 'Add money using Card',
    subtitle: 'Add money using either your credit or debit card.',
  },
  {
    image: radio_ngnusd,
    value: 'NAIRA',
    title: 'Add money using Naira wallet',
    subtitle: 'Add money using your Vesti naira wallet',
  },
  {
    image: radio_ngnusd,
    value: 'CEDIS',
    title: 'Add money using Cedis wallet',
    subtitle: 'Add money using your Vesti cedis wallet',
  },
];

export const Rewards = [
  'NGN 100  for each signUp',
  'NGN 300 for referred user who creates virtual card',
  'NGN 500 for referred user that successfully  payed for a service',
  '$10 :  USA based customer who created new Vesti account ( $10 added to USD wallet )',
  // `$50 :  USA based customer who created stripe financial account in their founder's  account ( $50 added to Financial Account )`
];

export const authWords = [
  {
    image: regimg,
    title: 'Wallets',
    subtitle:
      'Vesti money is the neobank for immigrants moving to the US/UK/EU.',
  },
  {
    image: regimgc,
    title: 'Cards',
    subtitle:
      'Spend safely online, in-store and abroad choosing from a variety of our cards.',
  },
  {
    image: regimgp,
    title: 'Pathways to move abroad',
    subtitle:
      'Find the ideal pathway and get tailored assistance to migrate to country of your choice.',
  },
  // {
  //   image: regimgf,
  //   title: 'Migration Fees',
  //   subtitle:
  //     'We offer comprehensive support to help you navigate and pay for fees such as WES, SEVIS and other immigration-related fees.',
  // },
];

export const shuttleProcess = [
  `Visit the Shuttlers Web app <a rel='noopener noreferrer' target='_blank' href="https://www.shuttlers.ng"> www.shuttlers.ng </a>  or download the Shuttlers app on 
    <a rel='noopener noreferrer' target='_blank' href="https://play.google.com/store/apps/details?id=com.shuttlers.android&hl=en&gl=US&pli=1"> Google play store </a>   or 
    <a rel='noopener noreferrer' target='_blank' href="https://apps.apple.com/ng/app/shuttlers/id1532662341">IOS</a>. `,
  `Fill your desired routes either through <strong>one-way</strong> or <strong>return trip</strong>  and select your preferred date.`,
  `Click on the <strong>Proceed to payment</strong> button to book your ticket and apply the 50% discount code from Vesti on your first Shuttle.`,
];

export const sidebarMenus = [
  { image: cash, name: 'Bank', route: '/bank', tip: 'My Wallet' },
  {
    image: pathway,
    name: 'Pathways',
    route: '/pathways',
    tip: 'Pathways',
  },
  { image: cards, name: 'Cards', route: '/cards', tip: 'My Cards' },
  {
    image: merchant,
    name: 'Migration Fees',
    route: '/merchants',
    tip: 'Migration Fees',
  },
  // {
  //   image: cc,
  //   name: 'Credit Card',
  //   route: '/credithistory',
  //   tip: 'Credit Card',
  // },
  // {
  //   image: airtime,
  //   name: 'Bills Payment',
  //   route: '/bills-payment',
  //   tip: 'My Wallet',
  // },

  { image: feed, name: 'Feeds', route: '/feeds', tip: 'Feeds' },

  // {
  //   image: member,
  //   name: 'Member',
  //   route: '/webinar',
  //   tip: 'My Memberships & Webinar',
  // },
];

export const sidebarAccountMenus = [
  // { image: feed, name: 'Feeds', route: '/feeds', tip: 'Feeds' },
  // {
  //   image: advice,
  //   name: 'Advice',
  //   route: '/advice',
  //   tip: 'Immigration Advice',
  // },
  {
    image: profile,
    name: 'My Profile',
    route: '/myprofile',
    tip: 'My Profile',
  },
];

export const cardColors = ['Black', 'Yellow', 'Green', 'Purple', 'Blue'];
export const cardCurrencies = ['NGN', 'GHS', 'KES', 'ZMW', 'GBP'];

// currencies

export const currencies = [
  {
    name: 'naira',
    currency: 'ngn',
    icon: naira,
  },
  {
    name: 'cedis',
    currency: 'ghs',
    icon: cedis,
  },
  {
    name: 'kwacha',
    currency: 'zmw',
    icon: kwacha,
  },
  {
    name: 'pesos',
    currency: 'pes',
    icon: pesos,
  },
  {
    name: 'pounds',
    currency: 'gbp',
    icon: pounds,
  },
  {
    name: 'kenya shillings',
    currency: 'kes',
    icon: shillings,
  },
  {
    name: 'Indian Rupees (coming soon)',
    currency: 'inr',
    icon:
      'https://res.cloudinary.com/wevesti/image/upload/v1724943396/verificationDocument/r5udgaytyhxtd9nhg1wm.png',
  },
  {
    name: 'Pakistani Rupees (coming soon)',
    currency: 'pkr',
    icon:
      'https://res.cloudinary.com/wevesti/image/upload/v1724943255/verificationDocument/yk2waypoo0altwcg0npc.png',
  },
  {
    name: 'Bangladeshi Taka (coming soon)',
    currency: 'bdt',
    icon:
      'https://res.cloudinary.com/wevesti/image/upload/v1724943459/verificationDocument/uj8oowjapbq8exd3kc6r.png',
  },
];

export const supportedWallets = [
  'ngn',
  'ghc',
  'ghs',
  'zmw',
  'kwacha',
  'busd',
  'usd',
  'gbp',
  'kes',
  'kenya shillings',
  'inr',
  'pkr',
  'bdt',
];

export const NigerianStates = [
  {
    value: 'Abia',
    label: 'Abia',
  },
  {
    value: 'Abuja',
    label: 'Abuja',
  },
  {
    value: 'Adamawa',
    label: 'Adamawa',
  },
  {
    value: 'Akwa Ibom',
    label: 'Akwa Ibom',
  },
  {
    value: 'Anambra',
    label: 'Anambra',
  },
  {
    value: 'Bauchi',
    label: 'Bauchi',
  },
  {
    value: 'Bayelsa',
    label: 'Bayelsa',
  },
  {
    value: 'Benue',
    label: 'Benue',
  },
  {
    value: 'Borno',
    label: 'Borno',
  },
  {
    value: 'Cross River',
    label: 'Cross River',
  },
  {
    value: 'Delta',
    label: 'Delta',
  },
  {
    value: 'Ebonyi',
    label: 'Ebonyi',
  },
  {
    value: 'Edo',
    label: 'Edo',
  },
  {
    value: 'Ekiti',
    label: 'Ekiti',
  },
  {
    value: 'Enugu',
    label: 'Enugu',
  },
  {
    value: 'Gombe',
    label: 'Gombe',
  },
  {
    value: 'Imo',
    label: 'Imo',
  },
  {
    value: 'Jigawa',
    label: 'Jigawa',
  },
  {
    value: 'Kaduna',
    label: 'Kaduna',
  },
  {
    value: 'Kano',
    label: 'Kano',
  },
  {
    value: 'Katsina',
    label: 'Katsina',
  },
  {
    value: 'Kebbi',
    label: 'Kebbi',
  },
  {
    value: 'Kogi',
    label: 'Kogi',
  },
  {
    value: 'Kwara',
    label: 'Kwara',
  },
  {
    value: 'Lagos',
    label: 'Lagos',
  },
  {
    value: 'Nassarawa',
    label: 'Nassarawa',
  },
  {
    value: 'Niger',
    label: 'Niger',
  },
  {
    value: 'Ogun',
    label: 'Ogun',
  },
  {
    value: 'Ondo',
    label: 'Ondo',
  },
  {
    value: 'Osun',
    label: 'Osun',
  },
  {
    value: 'Oyo',
    label: 'Oyo',
  },
  {
    value: 'Plateau',
    label: 'Plateau',
  },
  {
    value: 'Rivers',
    label: 'Rivers',
  },
  {
    value: 'Sokoto',
    label: 'Sokoto',
  },
  {
    value: 'Taraba',
    label: 'Taraba',
  },
  {
    value: 'Yobe',
    label: 'Yobe',
  },
  {
    value: 'Zamfara',
    label: 'Zamfara',
  },
];
